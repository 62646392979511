@use "../../foundation/import" as *;

/* -------------------------------
 *  common
 * ---------------------------- */
.p-page-header {
  width: 100%;
  height: 400px;
  position: relative;
  margin: 0 0 var(--space-XXXXL) 0;
  @include media-query(sm) {
    height: 300px;
  }
  .p-background-section  {
    width: 100%;
    height: 100%;
    .__image-wrap {
      width: 100%;
      height: 100%;
      .__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .p-foreground-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    & > *,
    & > * > *,
    & > * > * > * {
      width: 100%;
      height: 100%;
    }
    .__title {
      width: 100%;
      max-width: 380px;
      padding: var(--space-R);
      background: var(--primary-color__5);
      transform: translate(0, 50%);
      .__ja {
        font-size: rem(60);
        font-weight: bold;
        color: var(--color-white);
        text-shadow: 0 4px 0 rgba(0,0,0,0.25);
        line-height: 1;
        padding: 0 0 var(--space-R) 0;
        margin: 0 0 var(--space-R) 0;
        position: relative;
        @include media-query(sm) {
          font-size: var(--font-XXXXL);
        }
        &::after {
          content: "";
          width: 120px;
          height: 1px;
          background: var(--primary-color__3);
          position: absolute;
          bottom: 0;
          left: calc(50% - 60px);
        }
      }
      .__en {
        font-weight: 900;
        color: var(--primary-color__2);
        line-height: 1;
        font-size: var(--font-XL);
      }
    }
  }
} 
.p-category-item {
  .__thumbnail {
    width: 200px;
    height: 200px;
    box-shadow: var(--default-box-shadow);
    border-radius: 100%;
    margin: 0 auto;
    padding: var(--space-M);
    background: var(--color-white);
  }
  .__name {
    line-height: 1.4;
    margin: var(--space-S) 0 0 0;
    font-size: var(--font-XL);
    color: var(--primary-color__5);
  }
  .__button {
    width: 100%;
    max-width: 276px;
    margin: var(--space-S) auto 0 auto;
  }
}
.p-content-area-box {
  background: var(--color-white);
  padding: var(--space-R);
}
.p-highlight-content {
  padding: var(--space-R);
  background: var(--secondary-color__1);
  .__item {
    line-height: 1.4;
    color: var(--primary-color__5);
    &:not(:first-child) {
      margin: var(--space-XS) 0 0 0;
    }
  }
}

/* -------------------------------
 *  01_about
 * ---------------------------- */
.p-page-about {
  .p-section-01 {
    .__block-depth-2 {
      max-width: 776px;
      margin: 0 auto;
      .__visual {
        margin: var(--space-M) 0 0 0;
      }
      .__lead {
        margin: var(--space-M) 0 0 0;
        color: var(--secondary-color__6);
        font-size: var(--font-XXL);
        line-height: 1.4;
        @include media-query(md) {
          font-size: var(--font-L);
        }
      }
    }
  }
  .p-section-02 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      gap: var(--space-R);
      margin: var(--space-M) 0 0 0;
    }
    .__block-depth-3 {
      &.__block-depth-3--1st {
        width: calc(58.3333% - calc(var(--space-R) / 2));
      }
      &.__block-depth-3--2nd {
        width: calc(41.6666% - calc(var(--space-R) / 2));
      }
      &.__block-depth-3--1st,
      &.__block-depth-3--2nd {
        @include media-query(md) {
          width: 100%;
        }
      } 
    }
    .__profile {
      line-height: 1.4;
      .__main {
        font-size: var(--font-XXXL);
      }
    }
  }
  .p-section-03 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      max-width: 776px;
      margin: 0 auto;
      .__table {
        margin: var(--space-M) 0 0 0;
      }
    }
  }
  .p-section-04 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      max-width: 976px;
      margin: var(--space-M) auto 0 auto;
      gap: var(--space-R);
      .__block-depth-3 {
        width: calc(50% - calc(var(--space-R) / 2));
        @include media-query(md) {
          width: 100%;
        }
        &.__block-depth-3--1st {
          order: 2;
          @include media-query(md) {
            order: 1;
          }
        }
        &.__block-depth-3--2nd {
          order: 1;
          @include media-query(md) {
            order: 2;
          }
        }
        .__block-depth-4 {
          &:not(:first-child) {
            margin: var(--space-M) 0 0 0;
          }
        }
      }
    }
  }
} 

/* -------------------------------
 *  02_recruit
 * ---------------------------- */
 .p-page-recruit {
  .p-section-01 {
    .__block-depth-2 {
      margin: var(--space-M) 0 0 0;
      .__visual {
        margin: var(--space-M) 0 0 0;
      }
      .__lead {
        margin: var(--space-M) 0 0 0;
        color: var(--secondary-color__6);
        font-size: var(--font-XXL);
        line-height: 1.4;
        @include media-query(md) {
          font-size: var(--font-L);
        }
      }
    }
  }
  .p-section-02 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      margin: var(--space-M) 0 0 0;
      &.__block-depth-2--1st {
        .__table {
          & > table {
            min-width: 776px;
            tr {
              & > * {
                &:nth-child(1) {
                  width: 20%;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  width: 40%;
                }
              }
            }
            thead {
              tr {
                th {
                  padding: var(--space-S);
                  border-radius: var(--default-border-raduis) var(--default-border-raduis) 0 0;
                  font-size: var(--font-XL);
                  line-height: 1;
                  @include media-query(md) {
                    font-size: var(--font-L);
                  }
                  &:nth-child(2) {
                    background: var(--secondary-color__4);
                  }
                  &:nth-child(3) {
                    background: var(--primary-color__3);
                  }
                }
              }
            }
          }
        }
      }
      &.__block-depth-2--2nd {
        max-width: 976px;
        margin: var(--space-M) auto 0 auto;
        gap: var(--space-S) var(--space-R);
        .__block-depth-3 {
          width: calc(50% - calc(var(--space-R) / 2));
          @include media-query(md) {
            width: 100%;
          }
        }
        .__list {
          margin: var(--space-R) 0 0 0;
        }
      }
      &.__block-depth-2--3rd {
        max-width: 776px;
        margin: var(--space-M) auto 0 auto;
        .__box {
          gap: var(--space-R);
          .__element {
            width: calc(50% - calc(var(--space-R) / 2));
            @include media-query(md) {
              width: 100%;
            }
            &.__element--1st {
              .__copy {
                margin: 0 0 0 var(--space-XS);
                .__main {
                  font-size: var(--font-XL);
                  color: var(--secondary-color__5);
                  line-height: 1.2;
                  @include media-query(sm) {
                    font-size: var(--font-M);
                  }
                }
                .__caption {
                  margin: var(--space-XXXS) 0 0 0;
                  font-size: var(--font-S);
                  @include media-query(sm) {
                    font-size: var(--font-XS);
                  }
                }
              }
            }
            &.__element--2nd {
              .__copy {
                .__tel {
                  margin: 0 0 0 var(--space-XS);
                  font-size: var(--font-XXXL);
                  line-height: 1;
                  color: var(--accent-color__4);
                  @include media-query(sm) {
                    font-size: var(--font-XXL);
                  }
                }
              }
              .__contact {
                margin: var(--space-XXXS) 0 0 0;
              }
            }
          }
        }
      }
    }
  }
}

/* -------------------------------
 *  03_service
 * ---------------------------- */
 .p-page-service {
  .p-section-01 {
    .__block-depth-2 {
      &.__block-depth-2--1st {
        max-width: 976px;
        margin: var(--space-M) auto 0 auto;
      }
      &.__block-depth-2--2nd {
        margin: var(--space-M) 0 0 0;
        .__block-depth-3 {
          gap: var(--space-L) var(--space-R);
        }
        .__block-depth-4 {
          width: calc(25% - calc(calc(var(--space-R) * 3) / 4));
          @include media-query(xl) {
            width: calc(50% - calc(var(--space-R) / 2));
          }
          @include media-query(sm) {
            width: 100%;
          }
        }
      }
      &.__block-depth-2--3rd,
      &.__block-depth-2--4th,
      &.__block-depth-2--5th,
      &.__block-depth-2--6th {
        margin: var(--space-XXL) auto 0 auto;
        max-width: 976px;
        .__image-wrap {
          margin: var(--space-R) 0 0 0;
          border: 1px solid var(--base-color__3);
          padding: var(--space-R);
        }
        .__label,
        .__button-wrap {
          margin: var(--space-S) 0 0 0;
        }
      }
      &.__block-depth-2--3rd,
      &.__block-depth-2--6th {
        .__block-depth-3 {
          margin: var(--space-R) 0 0 0;
          gap: var(--space-R);
          .__block-depth-4 {
            width: calc(50% - calc(var(--space-R) / 2));
            @include media-query(sm) {
              width: 100%;
            }
          }
        }
      }
      &.__block-depth-2--4th,
      &.__block-depth-2--5th {
        .__block-depth-3 {
          margin: var(--space-R) 0 0 0;
          gap: var(--space-L) var(--space-R);
          .__block-depth-4 {
            width: calc(50% - calc(var(--space-R) / 2));
            @include media-query(md) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .p-section-02 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      max-width: 976px;
      margin: var(--space-M) auto 0 auto;
      .__box {
        margin: var(--space-M) 0 0 0;
      }
    }
  }
}

/* -------------------------------
 *  04_products
 * ---------------------------- */
 .p-page-products {
  .p-section-01 {
    .__block-depth-2 {
      &.__block-depth-2--1st {
        max-width: 976px;
        margin: var(--space-M) auto 0 auto;
        .__box {
          margin: var(--space-M) 0 0 0;
          .__headline {
            font-size: var(--font-XL);
            color: var(--secondary-color__6);
          }
          .__description {
            color: var(--secondary-color__5);
            font-size: var(--font-M);
          }
        }
      }
      &.__block-depth-2--2nd,
      &.__block-depth-2--3rd {
        margin: var(--space-M) 0 0 0;
      }
      &.__block-depth-2--3rd {
        .__block-depth-3 {
          gap: var(--space-L) var(--space-R);
        }
        .__block-depth-4 {
          width: calc(25% - calc(calc(var(--space-R) * 3) / 4));
          .__thumbnail {
            padding: 0;
          }
          @include media-query(xl) {
            width: calc(50% - calc(var(--space-R) / 2));
          }
          @include media-query(sm) {
            width: 100%;
          }
        }
      }
      &.__block-depth-2--4th,
      &.__block-depth-2--5th,
      &.__block-depth-2--6th {
        margin: var(--space-XXL) auto 0 auto;
        max-width: 976px;
        .__block-depth-3 {
          margin: var(--space-R) 0 0 0;
          gap: var(--space-R);
          .__block-depth-4 {
            width: calc(50% - calc(var(--space-R) / 2));
            @include media-query(md) {
              width: 100%;
            }
          }
        }
        .__highlight {
          margin: var(--space-R) 0 0 0;
        }
      }
    }
  }
  .p-section-02 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      max-width: 976px;
      margin: var(--space-M) auto 0 auto;
    }
    .__block-depth-3 {
      margin: var(--space-M) 0 0 0;
      gap: var(--space-R);
      .__block-depth-4 {
        width: calc(50% - calc(var(--space-R) / 2));
        @include media-query(md) {
          width: 100%;
        }
      }
    }
    .__text--notice {
      background: var(--color-white);
      padding: var(--space-R) var(--space-S);
      .__highlight {
        color: var(--accent-color__4);
        font-size: 1.25em;
      }
    }
  }
  .p-section-03 {
    margin: var(--space-XXL) 0 0 0;
    .__block-depth-2 {
      &.__block-depth-2--1st,
      &.__block-depth-2--2nd,
      &.__block-depth-2--4th {
        max-width: 976px;
        margin: var(--space-M) auto 0 auto;
      }
      &.__block-depth-2--3rd {
        margin: var(--space-M) 0 0 0;
        .__block-depth-3 {
          gap: var(--space-L) var(--space-R);
          .__block-depth-4 {
            width: calc(25% - calc(calc(var(--space-R) * 3) / 4));
            @include media-query(md) {
              width: calc(50% - calc(var(--space-R) / 2));
            }
            @include media-query(sm) {
              width: 100%;
            }
            .__image {
              width: 100%;
            }
            .__caption {
              margin: var(--space-S) 0 0 0;
              line-height: 1.4;
              font-size: var(--font-L);
              color: var(--primary-color__5);
            }
          }
        }
      }
      &.__block-depth-2--4th {
        .__box {
          margin: var(--space-M) 0 0 0;
          .__headline {
            font-size: var(--font-XL);
            color: var(--secondary-color__6);
          }
          .__description {
            color: var(--secondary-color__5);
            font-size: var(--font-M);
          }
        }
      }
    }
  }
}