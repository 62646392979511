@use "../../foundation/import" as *;

/* -------------------------------
 *  button components
 * ---------------------------- */
.u-button-default {
  background: var(--accent-color__4);
  padding: 1em;
  text-align: center;
  text-decoration: none;
  border-radius: 99em;
  width: 100%;
  max-width: 320px;
  position: relative;
  transition: transition(background);
  display: inline-block;
  &::before,
  &::after {
    content: "";
    width: 12px;
    height: 2px;
    border-radius: 2px;
    position: absolute;
    top: calc(50% - 1px);
    right: var(--space-R);
    background: var(--color-white);
    transform-origin: right center;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  @include hover-style() {
    background: var(--accent-color__3);
  }
  .__text {
    color: var(--color-white);
    font-weight: bold;
  }
  &.u-button-default--contact {
    max-width: 280px;
    &::before,
    &::after {
      display: none;
    }
  }
  &.u-button-default--icon-down {
    &::before {
      transform: translate(-5px, 5px) rotate(135deg);
    }
    &::after {
      transform: translate(-5px, 5px) rotate(45deg);
    }
  }
} 