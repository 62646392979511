@use "../../foundation/import" as *;

/* -------------------------------
 *  list components
 * ---------------------------- */
.u-list-unordered-disk {
  .__item {
    padding: 0 0 0 1.5em;
    position: relative;
    &:not(:first-child) {
      margin: var(--space-XS) 0 0 0;
    }
    &::before {
      content: "";
      width: 0.8em;
      height: 0.8em;
      background: var(--primary-color__4);
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 0.4em;
    }
  }
} 