@use "../../foundation/import" as *;

/* -------------------------------
 *  common
 * ---------------------------- */
.p-home-section-title-en {
  font-family: var(--font-inter);
  font-size: rem(90);
  font-weight: 900;
  line-height: 1;
  background: linear-gradient(180deg, #49B889 0%, #CAEFE0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  background-clip: text;
  text-fill-color: transparent;
  @include media-query(sm) {
    font-size: rem(60);
  }
} 

/* -------------------------------
 *  01_home
 * ---------------------------- */
.p-front-page {
  margin: 80px 0 0 0;
  .p-main-visulal {
    width: 100%;
    height: calc(100vh - 80px - 80px);
    position: relative;
    .p-background-section {
      width: 100%;
      height: 100%;
      .__iamge-wrap {
        width: 100%;
        height: 100%;
        padding: 0 var(--space-R);
        .__image {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .p-foreground-section {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &::before {
        content: "";
        width: calc(100% - var(--space-L));
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 18.75%, rgba(44, 169, 197, 0.8) 51.42%, rgba(255, 255, 255, 0) 81.77%);
        position: absolute;
        left: var(--space-R);
        top: 0;
        mix-blend-mode: multiply;
        z-index: 1;
      }
      & > *,
      & > * > *,
      & > * > * > * {
        width: 100%;
        height: 100%;
      }
      .__catch {
        position: relative;
        z-index: 2;
        padding: 0 var(--space-R);
        .__copy {
          line-height: 1.4;
          font-size: clamp(var(--font-XXXXL), 3.2vw, rem(80));
          font-weight: bold;
          color: var(--color-white);
          text-shadow: var(--default-text-shadow);
          @include media-query(sm) {
            font-size: var(--font-XXL);
          }
        }
      }
    }
    .p-text-roll-section {
      .__text-container {
        position: relative;
        height: rem(100);
        transform: translate(0, -50%);
        white-space: pre-line;
        word-break: keep-all;
        overflow: hidden;
      }
      .__text-list {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .__text-item {
        font-size: rem(100);
        font-family: var(--font-inter);
        font-weight: 900;
        line-height: 1;
        letter-spacing: 0.2em;
        color: var(--secondary-color__5);
        padding: 0 0.5em;
      }
    }
  }
  .p-section-01 {
    padding: var(--space-XXXL) 0 0 0;
    overflow: hidden;
    .__block-depth-1 {
      &.__block-depth-1--1st {
        .__block-depth-2 {
          margin: 0 0 0 auto;
          height: 420px;
          position: relative;
          .__image-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: calc(calc(calc(100vw - 100%) / 2) + 95.395%);
            height: 100%;
            border-radius: 80px 0 0 80px;
            overflow: hidden;
            &::before {
              content: "";
              width: 100%;
              height: 100%;
              background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0.7) 100%);
              position: absolute;
              left: 0;
              bottom: 0;
            }
            .__image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      &.__block-depth-1--2nd {
        position: relative;
        .__abstract-object {
          width: 100vw;
          height: 200px;
          background: var(--color-white);
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          transform: rotate(-45deg) translate(-30%, -50%);
          transform-origin: left center;
        }
        .__title {
          font-size: var(--font-XXXXL);
          line-height: 1.4;
          transform: translate(0, -50%);
          @include media-query(sm) {
            font-size: var(--font-XXXL);
          }
        }
        .__block-depth-2 {
          &.__block-depth-2--1st {
            max-width: 976px;
            margin: calc(var(--space-R) * -1) auto 0 auto;
          }
          &.__block-depth-2--2nd {
            .__block-depth-3 {
              display: flex;
              flex-wrap: wrap;
              gap: 0 var(--space-R);
              .__block-depth-4 {
                width: calc(33.3333% - 16px);
                margin: var(--space-L) 0 0 0;
                @include media-query(md) {
                  width: 100%;
                }
              }
              .__banner-box {
                .__link {
                  text-decoration: none;
                  .__banner {
                    position: relative;
                    &::before {
                      content: "";
                      width: calc(100% - var(--space-XXS));
                      height: calc(100% - var(--space-XXS));
                      position: absolute;
                      top: var(--space-XXXS);
                      left: var(--space-XXXS);
                      display: block;
                      border: 1px solid var(--color-white);
                      border-radius: 4px;
                      z-index: 1;
                    }
                    .__image {
                      width: 100%;
                      border-radius: var(--default-border-radius);
                      will-change: filter;
                      transition: transition(filter);
                    }
                    .__caption {
                      position: absolute;
                      right: calc(var(--space-XXS) * -1);
                      bottom: var(--space-R);
                      background: var(--primary-color__6);
                      display: inline-block;
                      line-height: 1;
                      font-size: var(--font-L);
                      color: var(--color-white);
                      padding: var(--space-XXXS) var(--space-XS);
                      z-index: 2;
                    }
                  }
                  @include hover-style() {
                    .__banner {
                      .__image {
                        filter: brightness(1.3);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .p-section-02 {
    padding: var(--space-XXXL) 0 0 0;
    @include media-query(md) {
      padding: var(--space-R) 0 0 0;
    }
    .__block-depth-2 {
      &.__block-depth-2--1st {
        transform: translate(0, 50%);
        position: relative;
        z-index: 2;
        @include media-query(md) {
          transform: translate(0, 25%);
        }
        .__title {
          line-height: 1;
          transform: translate(0, -50%);
          @include media-query(md) {
            transform: translate(0, 0);
            margin: 0 0 var(--space-M) 0;
          }
          .__en {
            transform: translate(5%, 30%);
            position: relative;
            z-index: 1;
          }
          .__ja {
            font-size: rem(54);
            position: relative;
            z-index: 2;
            @include media-query(sm) {
              font-size: var(--font-XXXXL);
            }
          }
        }
        .__block-depth-3 {
          .__block-depth-4 {
            width: 50%;
            @include media-query(md) {
              width: 100%;
            }
            .__text {
              background: var(--color-white);
              padding: var(--space-R);
            }
          }
        }
      }
      &.__block-depth-2--2nd {
        .__block-depth-3 {
          .__image-wrap {
            width: 100vw;
            margin: 0 calc(50% - 50vw);
            height: 400px;
            @include media-query(sm) {
              height: 320px;
            }
            .__image {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &.__block-depth-2--3rd {
        .__block-depth-3 {
          &.__block-depth-3--1st {
            transform: translate(0, calc(var(--space-XL) * -1));
            gap: var(--space-R);
            .__block-depth-4 {
              width: calc(50% - var(--space-XS));
              @include media-query(md) {
                width: 100%;
              }
            }
          }
        }
      }
      .__banner-box {
        border-radius: var(--default-border-radius);
        padding: var(--space-R);
        box-shadow: var(--default-box-shadow);
        height: 100%;
        .__thumbnail {
          .__image {
            width: 100%;
          }
        }
        .__heading {
          margin: var(--space-R);
          line-height: 1.4;
          color: var(--color-white);
          font-size: var(--font-XL);
          position: relative;
          padding: 0 0 var(--space-R) 0;
          &::after {
            content: "";
            width: 80px;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 40px);
          }
        }
        .__text {
          margin: var(--space-R) 0 0 0;
          color: var(--color-white);
        }
        &.__banner-box--1st,
        &.__banner-box--4th {
          background: linear-gradient(180deg, var(--primary-color__4) 0%, var(--primary-color__5) 100%);
          .__heading {
            &::after {
              background: var(--primary-color__3);
            }
          }
        }
        &.__banner-box--2nd,
        &.__banner-box--3rd {
          background: linear-gradient(180deg, var(--secondary-color__4) 0%, var(--secondary-color__5) 100%);;
          .__heading {
            &::after {
              background: var(--secondary-color__3);
            }
          }
        }
        @include media-query(md) {
          &.__banner-box--3rd {
            background: linear-gradient(180deg, var(--primary-color__4) 0%, var(--primary-color__5) 100%);
            .__heading {
              &::after {
                background: var(--primary-color__3);
              }
            }
          }
          &.__banner-box--4th {
            background: linear-gradient(180deg, var(--secondary-color__4) 0%, var(--secondary-color__5) 100%);
            .__heading {
              &::after {
                background: var(--secondary-color__3);
              }
            }
          }
        }
      }
    }
  }
  .p-section-03 {
    padding: var(--space-XXXL) 0 0 0;
    overflow: hidden;
    @include media-query(md) {
      padding: var(--space-XXL) 0 0 0;
    }
    .__block-depth-2 {
      grid-template-columns: calc(58.3333% - var(--space-XS)) calc(41.6666% - var(--space-XS));
      grid-template-rows: auto auto;
      gap: var(--space-R);
      @include media-query(md) {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        gap: var(--space-R) 0;
      }
      .__block-depth-3 {
        &.__block-depth-3--1st {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          align-self: start;
          margin-left: calc(calc(20% + 12px) * -1);
          position: relative;
          z-index: 2;
          @include media-query(lg) {
            margin-left: calc(calc(40% + 12px) * -1);
          }
          @include media-query(md) {
            grid-column: 1 / 3;
            grid-row: 1 / 2;
            margin-left: 0;
          }
          .__content {
            padding: var(--space-M) var(--space-R) var(--space-R) var(--space-R);
            position: relative;
            margin: var(--space-L) 0 var(--space-R) 0;
            &::before {
              content: "";
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: var(--color-white);
              position: absolute;
              z-index: 1;
              border-radius: 40px 0 0 40px;
              width: calc(50vw - 2px);
              @include media-query(lg) {
                width: calc(calc(50vw - 3px) + 14%);
              }
              @include media-query(md) {
                width: calc(100vw - 24px)
              }
            }
            .__title,
            .__text {
              position: relative;
              z-index: 2;
            }
            .__title {
              padding: 0 0 var(--space-R) 0;
              margin: 0 0 var(--space-R) 0;
              position: relative;
              &::after {
                content: "";
                width: 80px;
                height: 2px;
                background: var(--primary-color__4);
                position: absolute;
                left: 0;
                bottom: 0;
              }
              .__en {
                position: absolute;
                bottom: 100%;
                left: 5%;
                @include media-query(lg) {
                  font-size: rem(80);
                }
                @include media-query(sm) {
                  font-size: rem(60);
                }
              }
              .__ja {
                font-size: var(--font-XXL);
                line-height: 1.4;
                color: var(--primary-color__6);
                @include media-query(lg) {
                  font-size: var(--font-XL);
                }
                @include media-query(sm) {
                  font-size: var(--font-L);
                }
              }
            }
          }
          .__button {
            @include media-query(md) {
              margin: 0 auto;
            }
          }
        }
        &.__block-depth-3--2nd {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          position: relative;
          z-index: 1;
          @include media-query(md) {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
          }
          .__image-wrap {
            height: 500px;
            position: relative;
            @include media-query(sm) {
              height: 300px;
            }
            .__gallery-image {
              width: calc(calc(50vw + 14.2857%) - 10px);
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              @include media-query(md) {
                width: 100%;
              }
              .__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        &.__block-depth-3--3rd {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
          justify-content: center;
          @include media-query(md) {
            grid-column: 1 / 3;
            grid-row: 3 / 4;
          }
          .__gallery-thumbnail {
            .__thumbnail {
              margin: 0 var(--space-XS);
              .__image {
                border-radius: 100%;
              }
              .__caption {
                line-height: 1.2;
                font-size: var(--font-L);
                margin: var(--space-S) 0 0 0;
              }
            }
          }
        }
      }
    }
  }
  .p-section-04 {
    padding: var(--space-XXXL) 0 0 0;
    overflow: hidden;
    .__block-depth-1 {
      &.__block-depth-1--1st {
        .__block-depth-2 {
          &.__block-depth-2--1st {
            width: calc(41.6666% - var(--space-XS));
            @include media-query(md) {
              width: 100%;
              order: 2;
            }
            .__title {
              width: 100%;
              .__en {
                transform: translate(0, 13px);
              }
              .__ja {
                font-size: var(--font-XXXL);
                line-height: 1.6;
                padding: var(--space-R) 0;
                position: relative;
                width: 100%;
                &::before {
                  content: "";
                  height: 100%;
                  width: 120vw;
                  background: var(--color-white);
                  position: absolute;
                  left: calc(-50vw + 122.9%);
                  top: 0;
                  z-index: -1;
                  @include media-query(md) {
                    left: -24px;
                  }
                }
              }
            }
            .__button {
              margin: var(--space-M) 0 0 0;
            }
          }
          &.__block-depth-2--2nd {
            width: calc(58.3333% - var(--space-XS));
            @include media-query(md) {
              width: 100%;
              order: 1;
            }
            .__image-wrap {
              aspect-ratio: 3 / 2;
              width: 100%;
              height: 100%;
              position: relative;
              .__visual {
                position: absolute;
                left: 0;
                top: 0;
                aspect-ratio: 804 / 457;
                width: calc(50vw + 14.2857% - 10px);
                height: auto;
              }
              @include media-query(md) {
                aspect-ratio: auto;
                .__visual {
                  position: static;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      &.__block-depth-1--2nd {
        margin: var(--space-R) 0 0 0;
      }
    }
  }
} 