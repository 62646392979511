@use "../foundation/import" as *;

/* -------------------------------
 *  header
 * ---------------------------- */
 .l-header {
  grid-area: header;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--layer-order__fixed-content-default);
  .l-wrapper {
    transition: transition(background);
  }
  .__block-depth-1 {
    height: 80px;
  }
  .__block-depth-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .__block-depth-4 {
    height: 80px;
    &.__block-depth-4--desktop {
      @include media-query(lg) {
        display: none;
      }
    }
    &.__block-depth-4--mobile {
      display: none;
      @include media-query(lg) {
        display: flex;
      }
    }
  }
  .__block-depth-5 {
    &.__block-depth-5--top {
      .__contact-info {
        background: var(--primary-color__5);
        border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
        padding: var(--space-XXXS) var(--space-XXS);
        .__copy {
          color: var(--color-white);
          line-height: 1;
          .__main {
            font-size: var(--font-S);
          }
          .__sub {
            font-size: var(--font-XS);
            margin: var(--space-XXXS) 0 0 0;
          }
        }
        .__tel {
          color: var(--color-white);
          line-height: 1;
          margin: 0 0 0 var(--space-XXS);
          .__number {
            font-size: var(--font-XXL);
            font-weight: bold;
            margin: 0 0 0 var(--space-XXS);
          }
        }
      }
    }
  }
}