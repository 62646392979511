@use "../foundation/import" as *;

/* -------------------------------
 *  main
 * ---------------------------- */
 .l-main {
  grid-area: main;
}

/* -------------------------------
 *  aside
 * ---------------------------- */
.l-aside-cv {
  position: relative;
  .__background {
    .__image-wrap {
      width: 100%;
      height: 460px;
      .__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .__foreground {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .__block-depth-1 {
      max-width: 776px;
      margin: 0 auto;
    }
  }
}
.l-conversion-area {
  background: var(--color-white);
  border-radius: var(--default-border-radius);
  transform: translate(0, var(--space-XL));
  .__heading {
    font-size: var(--font-XXL);
    color: var(--color-white);
    background: var(--secondary-color__6);
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    line-height: 1.4;
    padding: var(--space-XS);
    @include media-query(md) {
      font-size: var(--font-L);
    }
  }
  .__body {
    padding: var(--space-R);
  }
  .__block-depth-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .__block-depth-3 {
    width: 50%;
    margin: var(--space-R) 0 0 0;
    padding: 0 var(--space-XS);
    @include media-query(md) {
      width: 100%;
    }
    &.__block-depth-3--1st {
      border-right: 1px solid var(--base-color__3);
      @include media-query(md) {
        padding: 0 var(--space-XS) var(--space-R) var(--space-XS);
        border-right: none;
        border-bottom: 1px solid var(--base-color__3);
      }
    }
    .__tel {
      .__number {
        font-size: var(--font-XXXL);
        font-weight: bold;
        margin: 0 0 0 var(--space-XXS);
        color: var(--primary-color__5);
        line-height: 1;
        @include media-query(xs) {
          font-size: var(--font-XXL);
        }
      }
      & + .__caption {
        font-size: var(--font-XS);
      }
    }
    .__button {
      .__text {
        margin: 0 0 0 var(--space-XXS);
      }
    }
  }
}