@use "variable" as *;

/* -------------------------------
 *  media query
 * ---------------------------- */
@mixin media-query($breakpoint-key, $rule: max-width) {
  @if $rule == max-width {
    @media (max-width: #{map-get($breakpoint-list, $breakpoint-key)}px) {
      @content;
    }  
  } @else if $rule == min-width {
    @media (min-width: calc(#{map-get($breakpoint-list, $breakpoint-key)}px + 1px)) {
      @content;
    }
  }
}

/* -------------------------------
 *  container query
 * ---------------------------- */
// @mixin container-query($name, $width, $rule: max-width) {
//   @if $rule == max-width {
//     @container #{$name} (max-width: #{$width}px) {
//       @content;
//     }
//   } @else if $rule == min-width {
//     @container #{$name} (min-width: #{$width}px) {
//       @content;
//     }
//   }
// }

/* -------------------------------
 *  hover & focus
 * ---------------------------- */
@mixin hover-style($disabled: false) {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
  @if $disabled {
    &:hover {
      @content;
    }
  }
}
@mixin focus-style {
  &:focus {
    @content;
  }
}

/* -------------------------------
 *  screen reader
 * ---------------------------- */  
@mixin screen-reader-style {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  left: -9999px;
  top: -9999px;
}

/* -------------------------------
 *  clearfix
 * ---------------------------- */  
@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

/* -------------------------------
 *  hack
 * ---------------------------- */
@mixin hack-ie11 {
  @media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, body {
      @content;
    }
  }
}
@mixin hack-edge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}
@mixin hack-firefox {
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin hack-chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
@mixin hack-safari($selecter: "*.hack-safari") {
  _::-webkit-full-page-media, _:future, :root #{$selecter} {
    @content;
  }
}