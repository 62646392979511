@use "../../foundation/import" as *;

/* -------------------------------
 *  table components
 * ---------------------------- */
.u-table-wrap {
  & > table {
    width: 100%;
  }
  &.u-table-wrap--responsive {
    overflow-x: auto;
  }
}

.u-table-default {
  border-bottom: 1px solid var(--base-color__3);
  tbody  {
    tr {
      &:nth-child(odd) {
        background: var(--base-color__1);
      }
      &:nth-child(even) {
        background: var(--color-white);
      }
      th,
      td {
        padding: var(--space-S) var(--space-R);
        font-weight: normal;
        vertical-align: top;
        text-align: left;
        border-top: 1px solid var(--base-color__3);
      } 
    }
  }
  &.u-table-default--mobilable {
    tbody  {
      tr {
        th,
        td {
          @include media-query(md) {
            display: block;
            width: 100%;
          }
        }
        @include media-query(md) {
          th {
            padding: var(--space-S) var(--space-R) 0 var(--space-R);
          }
          td {
            border-top: none;
          }
        }
      }
    }
  }
}