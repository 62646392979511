@use "../foundation/import" as *;

/* -------------------------------
 *  html body
 * ---------------------------- */
html {
  width: 100%;
  height: 100%;
  body {
    width: inherit;
    height: inherit;
    min-height: 100vh;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
    letter-spacing: var(--default-letter-spacing);
    color: var(--default-color);
    font-feature-settings: "palt";
    background: var(--primary-color__1);
  }
  @include media-query(md) {
    body {
      font-size: clamp(var(--font-S), responsive-font-size(16, $screen-md), var(--font-R));
    }
  }
  @include media-query(xs) {
    body {
      font-size: var(--font-S);
    }
  }
}

/* -------------------------------
 *  base layout
 * ---------------------------- */

/* -- config ------------------ */
$header-min-height: auto;
$header-max-height: auto;
$footer-min-height: auto;
$footer-max-height: auto;

/* -- layout ------------------ */
.l-base {
  width: inherit;
  height: auto !important;
  min-height: 100vh;
  position: relative;
  // overflow: hidden;
  display: grid;
  grid-template:
    "header" minmax($header-min-height, $header-max-height)
    "main" 1fr
    "footer" minmax($footer-min-height, $footer-max-height)
    / 100%;
}