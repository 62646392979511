@use "../../foundation/import" as *;

/* -------------------------------
 *  navigation elements
 * ---------------------------- */
.c-global-navi {
  .__item {
    &:not(:first-child) {
      margin: 0 0 0 var(--space-M);
    }
  }
  .__link {
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    .__ja {
      color: var(--secondary-color__6);
      font-size: var(--font-R);
    }
    .__en {
      color: var(--secondary-color__4);
      font-size: var(--font-XS);
      margin: 0 0 0 var(--space-XXS);
    }
  }
}

.c-footer-sitemap {
  .__list {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .__item {
    margin: var(--space-XS) 0 0 0;
    &:not(:last-child) {
      margin: 0 1em 0 0;
      padding: 0 1em 0 0;
      line-height: 1;
      border-right: 1px solid var(--primary-color__2);
    }
  }
  .__link {
    text-decoration: none;
    color: var(--color-white);
  }
}