@use "../../foundation/import" as *;

/* -------------------------------
 *  heading components
 * ---------------------------- */
.u-heading-level-1 {
  position: relative;
  &::before {
    content: "";
    width: calc(calc(calc(100vw - 100%) / 2) + 100%);
    height: 100%;
    background: var(--secondary-color__1);
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 4px 4px 0 var(--secondary-color__3);
  }
  .__copy {
    position: relative;
    z-index: 1;
    display: block;
    padding: var(--space-XS) var(--space-R);
    border-left: 4px solid var(--secondary-color__5);
    font-size: var(--font-XXL);
    line-height: 1.4;
    @include media-query(md) {
      font-size: var(--font-L);
    }
  }
} 

.u-heading-level-2 {
  .__copy {
    font-size: var(--font-XL);
    color: var(--secondary-color__5);
  }
  .__bar {
    margin: 0 0 0 0.5em;
    width: auto;
    height: 3px;
    background: var(--base-color__3);
  }
}

.u-heading-level-3 {
  padding: var(--space-XS) var(--space-R);
  border-left: 5px solid var(--primary-color__4);
  font-size: var(--font-L);
  line-height: 1.4;
}