@use "../../foundation/import" as *;

/* -------------------------------
 *  text components
 * ---------------------------- */
.u-text-en {
  font-family: var(--font-inter);
}

.u-link-default {
  color: var(--accent-color__4);
}

.u-text-small {
  font-size: 0.75em;
}