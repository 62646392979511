@use "../../foundation/import" as *;

/* -------------------------------
 *  flexbox layout
 * ---------------------------- */
.u-flex-start-start,
.u-flex-start-center,
.u-flex-start-end,
.u-flex-start-stretch,
.u-flex-start-baseline,
.u-flex-center-start,
.u-flex-center-center,
.u-flex-center-end,
.u-flex-center-stretch,
.u-flex-center-baseline,
.u-flex-end-start,
.u-flex-end-center,
.u-flex-end-end,
.u-flex-end-stretch,
.u-flex-end-baseline,
.u-flex-between-start,
.u-flex-between-center,
.u-flex-between-end,
.u-flex-between-stretch,
.u-flex-between-baseline,
.u-flex-around-start,
.u-flex-around-center,
.u-flex-around-end,
.u-flex-around-stretch,
.u-flex-around-baseline {
  display: flex;
  flex-direction: row;
  &.u-flex-wrap {
    flex-wrap: wrap;
  }
  &.u-flex-column {
    flex-direction: column;
  }
  &.u-flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.u-flex-column-reverse {
    flex-direction: column-reverse;
  }
  & > .u-flex-grow-1 {
    flex-grow: 1;
  }
  & > .u-flex-grow-0 {
    flex-grow: 0;
  }
  & > .u-flex-shrink-1 {
    flex-shrink: 1;
  }
  & > .u-flex-shrink-0 {
    flex-shrink: 0;
  }
}
.u-flex-start-start,
.u-flex-start-center,
.u-flex-start-end,
.u-flex-start-stretch,
.u-flex-start-baseline {
  justify-content: flex-start;
}
.u-flex-center-start,
.u-flex-center-center,
.u-flex-center-end,
.u-flex-center-stretch,
.u-flex-center-baseline {
  justify-content: center;
}
.u-flex-end-start,
.u-flex-end-center,
.u-flex-end-end,
.u-flex-end-stretch,
.u-flex-end-baseline {
  justify-content: flex-end;
}
.u-flex-between-start,
.u-flex-between-center,
.u-flex-between-end,
.u-flex-between-stretch,
.u-flex-between-baseline {
  justify-content: space-between;
}
.u-flex-around-start,
.u-flex-around-center,
.u-flex-around-end,
.u-flex-around-stretch,
.u-flex-around-baseline {
  justify-content: space-around;
}
.u-flex-start-start,
.u-flex-center-start,
.u-flex-end-start,
.u-flex-between-start,
.u-flex-around-start {
  align-items: flex-start;
}
.u-flex-start-center,
.u-flex-center-center,
.u-flex-end-center,
.u-flex-between-center,
.u-flex-around-center {
  align-items: center;
}
.u-flex-start-end,
.u-flex-center-end,
.u-flex-end-end,
.u-flex-between-end,
.u-flex-around-end {
  align-items: flex-end;
}
.u-flex-start-stretch,
.u-flex-center-stretch,
.u-flex-end-stretch,
.u-flex-between-stretch,
.u-flex-around-stretch {
  align-items: stretch;
}
.u-flex-start-baseline,
.u-flex-center-baseline,
.u-flex-end-baseline,
.u-flex-between-baseline,
.u-flex-around-baseline {
  align-items: baseline;
}

/* -------------------------------
 *  grid layout
 * ---------------------------- */  
 .u-grid {
  display: grid;
 }

/* -------------------------------
 *  alignment
 * ---------------------------- */
.u-align-left {
  text-align: left;
}
.u-align-center {
  text-align: center !important;
}
.u-align-right {
  text-align: right;
}
.u-block-left {
  margin-left: 0;
  margin-right: auto;
}
.u-block-center {
  margin-left: auto;
  margin-right: auto;
}
.u-block-right {
  margin-left: auto;
  margin-right: 0;
}

/* -------------------------------
 *  font style
 * ---------------------------- */
.u-bold {
  font-weight: bold;
}
.u-regular {
  font-weight: normal;
}
.u-italic {
  font-style: italic;
}
.u-underline {
  text-decoration: underline;
}
.u-strike {
  text-decoration: line-through;
}
.u-upper-case {
  text-transform: uppercase;
}
.u-lower-case {
  text-transform: lowercase;
}
.u-capital-case {
  text-transform: capitalize;
}

/* -------------------------------
 *  float layout
 * ---------------------------- */
.u-float-left {
  float: left;
}
.u-float-right {
  float: right;
}
.u-float-none {
  float: none;
}

/* -------------------------------
 *  display
 * ---------------------------- */
.u-block {
  display: block;
}
.u-inline {
  display: inline;
}
.u-inline-block {
  display: inline-block;
}
.u-none {
  display: none;
}

/* -------------------------------
 *  position
 * ---------------------------- */
.u-static {
  position: static;
} 
.u-absolute {
  position: absolute;
}
.u-relative {
  position: relative;
}
.u-fixed {
  position: fixed;
}
.u-sticky {
  position: sticky;
}