@use "../../foundation/import" as *;

/* -------------------------------
 *  parts styles
 * ---------------------------- */
.c-hamburger {
  font-size: 0;
  width: 50px;
  height: 30px;
  background: linear-gradient(0deg, var(--primary-color__6) 0, var(--primary-color__6) 6.6666%, transparent 6.6666%, transparent 100%);
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background: var(--primary-color__6);
    position: absolute;
    right: 0;
  }
  &::before {
    top: 0;
  }
  &::after {
    top: calc(50% - 1px);
  }
}

.c-drawer-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: var(--layer-order__drawer-navi);
  transform: translate(-100%, 0);
  opacity: 0;
  visibility: hidden;
  transition: transition(transform), transition(opacity), transition(visibility);
  &.c-drawer-menu--shown {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
  .__panel {
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--primary-color__2);
  }
  .__item {
    &:not(:first-child) {
      margin: var(--space-R) 0 0 0
    }
  }
  .__link {
    text-decoration: none;
    .__ja {
      font-size: var(--font-XL);
      color: var(--primary-color__6);
    }
    .__en {
      margin: 0 0 0 var(--space-S);
      color: var(--secondary-color__5);
    }
  }
}

.c-drawer-close {
  position: absolute;
  right: var(--space-R);
  top: var(--space-R);
  width: 40px;
  height: 40px;
  .__button {
    width: 100%;
    height: 100%;  
    font-size: 0;
    &::before,
    &::after {
      content: "";
      width: 44px;
      height: 2px;
      background: var(--base-color__6);
      border-radius: 4px;
      position: absolute;
      right: calc(50% - 22px);
      top: calc(50% - 1px);
      transform-origin: center center;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.c-animate-infinity-loop {
  will-change: transform;
  animation: animate-infinity-loop 45s linear 0.1s infinite both;
}

.c-anchor-point {
  margin-top: -80px;
  padding-top: 80px;
}

.c-contact-navi {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: var(--layer-order__fixed-content-default);
  display: none;
  @include media-query(md) {
    display: block;
  }
  .__box {
    & > * {
      width: 50%;
    }
  }
  .__mail,
  .__tel {    
    width: 100%;
    height: 40px;
    font-size: var(--font-S);
    color: var(--color-white);
  }
  .__mail {
    background: var(--secondary-color__5);
  }
  .__tel {
    background: var(--accent-color__4);
  }
}

.c-gallery-thumbnail {
  .__thumbnail {
    @include hover-style() {
      cursor: pointer;
    }
  }
}