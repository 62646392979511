@use "../foundation/import" as *;

/* -------------------------------
 *  footer
 * ---------------------------- */
 .l-footer {
  grid-area: footer;
  margin: var(--space-XXXL) 0 0 0;
  @include media-query(md) {
    margin: var(--space-XXXL) 0 40px 0;
  }
  .l-footer-inner {
    background: var(--primary-color__5);
    padding: var(--space-XXL) 0 var(--space-L);
    .__block-depth-1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include media-query(md) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .__block-depth-2 {
      &.__block-depth-2--1st {
        flex-shrink: 1;
        flex-grow: 0;
        .__block-depth-3 {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          @include media-query(md) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .__block-depth-4 {
          &.__block-depth-4--1st {
            margin: 0 var(--space-R) var(--space-R) 0;
            @include media-query(md) {
              margin: 0 0 var(--space-R) 0;
            }
          }
          .__address {
            color: var(--color-white);
          }
        }
      }
      &.__block-depth-2--2nd {
        flex-shrink: 0;
        flex-grow: 1;
      }
    }
  }
  .l-map-area {
    .__map-wrap {
      height: 300px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .l-copyright {
    background: var(--primary-color__6);
    padding: var(--space-XXXS);
    .__copyright {
      font-size: var(--font-XXS);
      color: var(--color-white);
      line-height: 1;
    }
  }
}