@use "../../foundation/import" as *;

/* -------------------------------
 *  box components
 * ---------------------------- */
.u-image-corner {
  border-radius: var(--default-border-radius);
}

.u-box-notice {
  background: var(--color-white);
  border: 2px solid var(--base-color__3);
  border-radius: var(--default-border-radius);
  padding: var(--space-R);
}